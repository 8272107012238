import React from 'react';
import kebabCase from 'lodash/kebabCase';

import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { InlineLink } from '../elements/InlineLink';
import { BlogHeader } from '../elements/BlogHeader';

const TagsPage = ({
  data: {
    allMdx: { group },
  },
}) => (
  <Layout>
    <BlogHeader title='Tags' linkTo='/blog' linkString='View Posts By Date' />
    <ul style={{ listStyleType: 'none', margin: '1em', padding: 0 }}>
      {group.map((tag) => (
        <li key={tag.fieldValue}>
          <InlineLink to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            {tag.fieldValue} ({tag.totalCount})
          </InlineLink>
        </li>
      ))}
    </ul>
  </Layout>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMdx(limit: 100) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
