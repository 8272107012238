import styled from 'styled-components';
import React from 'react';
import { InlineLink } from './InlineLink';

export const BlogHeader = ({ title, linkString, linkTo }) => (
  <StyledTitle>
    <h1>{title}</h1>
    <InlineLink style={{ fontSize: '1.2em' }} to={linkTo}>
      {linkString}
    </InlineLink>
  </StyledTitle>
);

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
